import { useLogger, useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { TermsAndConditionApp } from '../web/terms-and-conditions/TermsAndConditionApp'
export const Fragment = (props: any) => {
  const logger = useLogger()
  const userContext = useUserContext()
  logger.log('Rendering fragment for a locale', userContext.locale)
  useSwanStyleKeys(['core'])

  return <TermsAndConditionApp {...props} />
}
