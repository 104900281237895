import { useLocale } from '@vp/digital-locale-lib-ubik'
import { useSearchedDomainValue } from '@vp/digital-search-component-lib-ubik'
import { useState } from 'react'
import styles, { stylesheet } from './WixDomainsTermsAndConditionsPage.scss'
import { useLogger, useStyles } from '@vp/ubik-context'
import Redirect from '../../common/Redirect'
import { SEOContainer } from '@vp/digital-seo-lib-ubik'
import { BoundedContent, Box, Button, Column, FluidImage, GridContainer, Row, Typography } from '@vp/swan'
import { LinkFactory, TokenReplacer } from '@vp/digital-token-replacer-lib-ubik'
import { CONTINUE_CTA_DETAIL, TNC_HERO_LABEL } from './WixDomainsTermsAndConditionsAnalytics'
import { getWixPartnerConnectDomainBuildWebsiteUrl, getWixPartnerReviewAndBuyDomainUrl } from '../../common/helpers/wixDomainsUrls'
import { urlParamsEnum } from '../../common/enum/urlParamsEnum'
import { sourceEnum } from '../../common/enum/sourceEnum'
const WixDomainsTermsAndConditionsPage = (pageContext:any) => {
  useStyles(stylesheet)
  const { locale, hero, seo } = pageContext.pageData
  const logger = useLogger()

  const { languageCode } = useLocale()
  const [redirectPath, setRedirectPath] = useState<string | null>(null)
  const [isRedirecting, setIsRedirecting] = useState(false)
  const [domain] = useSearchedDomainValue('')

  let hasOwnDomain: boolean = false
  let source: string = null
  let domainName: string = domain ?? null
  if (typeof window !== 'undefined' && window.location?.search) {
    const queryString = window.location.search
    const urlParams = new URLSearchParams(queryString)
    hasOwnDomain =
      urlParams?.get(urlParamsEnum.HAS_OWN_DOMAIN)?.toLowerCase() === 'true'
    source =
      urlParams?.get(urlParamsEnum.SOURCE)?.toLowerCase() ??
      sourceEnum.DOMAINS_TERMS_AND_CONDITIONS
    domainName = urlParams?.get(urlParamsEnum.DOMAIN_NAME)?.toLowerCase()
  }

  const wixPartnerReviewAndBuyDomainsUrl = getWixPartnerReviewAndBuyDomainUrl(
    locale,
    sourceEnum.DOMAINS_TERMS_AND_CONDITIONS,
    domainName,
    source
  )

  const wixPartnerConnectDomainsUrl = getWixPartnerConnectDomainBuildWebsiteUrl(
    locale,
    sourceEnum.DOMAINS_TERMS_AND_CONDITIONS,
    domainName,
    source
  )

  const handleAcceptAndContinueNavigation = () => {
    logger.debug('Clicking Wix Domains Terms and Conditions Accept & Continue')
    const url = hasOwnDomain
      ? wixPartnerConnectDomainsUrl
      : wixPartnerReviewAndBuyDomainsUrl
    setIsRedirecting(true)
    setRedirectPath(url)
  }

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <>
        <SEOContainer content={seo} />
        {/* Terms & Conditions hero */}
        {hero && (
          <Box className={styles.wixTCPageHeroContainer}>
            <Box className={styles.wixTCPageHeroContent}>
              <BoundedContent>
                <GridContainer>
                  <Row>
                    <Column
                      span={6}
                      className={styles.wixTCPageHeroContentLeft}
                    >
                      <FluidImage
                        className={styles.wixTCPageHeroLogo}
                        src={hero.backgroundImage.media.secureUrl}
                        alt={hero.backgroundImage.media.fileName}
                      />
                      <Typography
                        m={0}
                        mt='6'
                        component='h1'
                        textColor='standard'
                        fontSize='x3large'
                        darkMode
                      >
                        {hero.title}
                      </Typography>
                      <Typography
                        m={0}
                        mt='5'
                        component='p'
                        textColor='standard'
                        darkMode
                      >
                        <TokenReplacer
                          template={hero.descriptionLong}
                          replacements={{
                            link: LinkFactory({
                              wixTermsOfUseUrl: `https://${languageCode}.wix.com/about/terms-of-use`,
                              wixPrivacyPolicyUrl: `https://${languageCode}.wix.com/about/privacy`,
                            }),
                          }}
                          convertMarkdown
                        />
                      </Typography>

                      <Box
                        mt='7'
                        className={styles.wixTCPageHeroButtonsContainer}
                      >
                        <Button
                          mr='6'
                          skin='secondary'
                          onClick={() => {
                            handleAcceptAndContinueNavigation()
                          }}
                          data-section={TNC_HERO_LABEL}
                          data-position='1'
                          data-translation={CONTINUE_CTA_DETAIL}
                        >
                          {hero.ctaName}
                        </Button>
                      </Box>
                    </Column>
                  </Row>
                </GridContainer>
              </BoundedContent>
              <FluidImage
                className={styles.wixTCPageHeroBackgroundImage}
                src={hero.mediaList[1].media.secureUrl}
                alt={hero.mediaList[1].altText}
              />
            </Box>
          </Box>
        )}
      </>
    </Redirect>
  )
}

export default WixDomainsTermsAndConditionsPage
