import React from 'react'
import { LocaleProvider } from '@vp/digital-locale-lib-ubik'
import {
  getClientEnvironment,
  RuntimeContextProvider,
} from '@vp/digital-environment-lib-ubik'
import { HtmlAttributes, RootLayout } from '@vp/digital-site-layout-lib-ubik'
import { IdentityProvider, VPAuthConfig } from '@vp/ubik-context'

import { ScriptKeys, StyleKeys } from '../common/swanKeys'
import WixDomainsTermsAndConditionsPage from './src/WixDomainsTermsAndConditionsPage'
import { PricingContentProvider, PricingContextProvider } from '@vp/digital-pricing-lib-ubik'
export function TermsAndConditionApp (props: any) {
  const createAuthConfig = (
    culture: string,
    isDevelopment: boolean
  ): VPAuthConfig => ({
    culture,
    developmentMode: isDevelopment,
    options: {
      requireSession: true,
      customText: 'Sign in to see your account',
    },
  })

  return (
    <LocaleProvider rawLocaleIdentifier={props.locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <IdentityProvider auth={createAuthConfig(props.locale, false)}>
          <PricingContextProvider>
            <PricingContentProvider pricingContent={props.billingPeriods}>
              <RootLayout
                swanStyleKeys={StyleKeys}
                swanScriptKeys={ScriptKeys}
              >
                <HtmlAttributes />

                <WixDomainsTermsAndConditionsPage {...props} />
              </RootLayout>
            </PricingContentProvider>
          </PricingContextProvider>
        </IdentityProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  )
}
