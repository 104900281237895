// Analytics related to the Wix Domains Terms and Conditions page
import {
  trackNavigationEvent,
  analyticsDataRegistry,
  trackPageViewed,
} from '@vp/digital-tracking-lib-ubik'

enum TermsAndConditionsPageZones {
  Hero = 'Hero',
}

const { wixDomainsTermsAndConditions } = analyticsDataRegistry

const NAVIGATION_CLICKED_ACTION = 'Navigation Clicked'
const TNC_NAVIGATION_LABEL = 'Content'

export const CONTINUE_CTA_DETAIL = 'Accept & Continue'
export const TNC_HERO_LABEL = 'Content:Hero'

// For enabling better QM Tracking
// Refer for detail: https://vistaprint.atlassian.net/wiki/spaces/GA/pages/2354546498/Phase+1+-+Vista+x+WIX+Partnership#Tracking-for-QM
export const PRIMARY_CTA_DATA_SECTION_LABEL =
  (wixDomainsTermsAndConditions?.pageSection ?? 'defaultSection') + ':' + TNC_NAVIGATION_LABEL

// Fires a page load event,
export function trackDomainsTermsAndConditionsPageLoad (): void {
  if (wixDomainsTermsAndConditions) {
    trackPageViewed(wixDomainsTermsAndConditions)
  } else {
    console.error('wixDomainsTermsAndConditions is undefined')
  }
}

export function trackDomainsTermsAndConditionsHeroCTAClick (
  destinationUrl: string
) {
  const wixDestinationDomains = {
    pageUri: destinationUrl?.split('?')[0],
  }
  // Navigates to websites page
  if (wixDomainsTermsAndConditions) {
    trackNavigationEvent(
      NAVIGATION_CLICKED_ACTION,
      TNC_NAVIGATION_LABEL,
      wixDomainsTermsAndConditions, // source
      wixDestinationDomains, // target
      TermsAndConditionsPageZones.Hero,
      CONTINUE_CTA_DETAIL
    )
  } else {
    console.error('wixDomainsTermsAndConditions is undefined')
  }
}
